import axios from 'axios'
import authHeader from '../Auth/AuthHeader'

import { API_URL } from '../Constants'

import { useSnackbar } from 'notistack'
import { NetworkErrSnack } from '../Components/Snacks'

import CachedIcon from '@material-ui/icons/Cached'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

export default function useApi() {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    return {
        get: (path, config) => {
            config = setConfig(config)

            var url = new URL(API_URL + path)
            url.searchParams.append('timestamp', new Date().getTime())

            return axios.get(url, config)
                .then(res => res.data)
                .catch(err => {
                    handleError(err, enqueueSnackbar, closeSnackbar)
                    throw err
                })
        },
        post: (path, body, config) => {
            config = setConfig(config)
            return axios.post(API_URL + path, body, config)
                .then(res => res.data)
                .catch(err => {
                    handleError(err, enqueueSnackbar, closeSnackbar)
                    throw err
                })
        },
        put: (path, body, config) => {
            config = setConfig(config, body)
            return axios.put(API_URL + path, config)
                .then(res => res.data)
                .catch(err => {
                    handleError(err, enqueueSnackbar, closeSnackbar)
                    throw err
                })
        },
        delete: (path, body, config) => {
            config = setConfig(config)
            config.data = body
            return axios.delete(API_URL + path, config)
                .then(res => res.data)
                .catch(err => {
                    handleError(err, enqueueSnackbar, closeSnackbar)
                    throw err
                })
        }
    }
}

function handleError(err, enqueueSnackbar, closeSnackbar) {

    let message
    let secondaryButton

    if (err.response) {
        //err.response.data /// status
        message = err.response.data.message

        if (err.response.status === 403) {
            switch (err.response.data.code) {
                case 'incorrect_password':
                    message = 'Helytelen felhasználó név vagy jelszó.'
                    secondaryButton = {
                        func: () => window.location.href = 'https://smilezor.hu/wp-login.php?action=lostpassword',
                        message: 'Új jelszó',
                        icon: VpnKeyIcon
                    }
                    break
                case 'jwt_auth_invalid_token':
                    window.location.href = '/logout'
                    return
                    break
                case 'jwt_auth_no_auth_header':
                    message = 'Hiányzó token! Jeletkezzeb be újra!'
                    secondaryButton = {
                        func: () => window.location.href = '/logout',
                        message: 'Bejelentkezés',
                        icon: VpnKeyIcon
                    }
                    break
                case 'jwt_auth_bad_config':
                    break

                default:
                    break
                //window.location.href = '/logout'
            }
        }

    } else if (err.request) {
        message = 'Hiba a szerverrel.'
    } else {
        console.log('err', err.message);
    }

    enqueueSnackbar(
        message,
        NetworkErrSnack(closeSnackbar, secondaryButton)
    )
}

function setConfig(config) {
    if (!config) config = { headers: {} }
    if (!config.headers) config.headers = {}
    config.headers['Authorization'] = authHeader()
    return config
}