import { useState, useContext } from "react"
import { TextField, Button, Paper } from "@material-ui/core"
import { Redirect } from "react-router-dom"
import AuthContext from "../Auth/AuthContext"
import useAuthService from "../Auth/AuthService"

import { LOGO_URL } from '../Constants'

export default function Login(props) {

    const AuthService = useAuthService()
    const context = useContext(AuthContext)

    const [loading, setLoading] = useState(true)
    const [creditals, setCreditals] = useState({
        username: '',
        password: ''
    })

    const onInputChange = async (e) => {
        const { name, value } = e.target
        creditals[name] = value
        setCreditals({ ...creditals })
    }

    const onLogin = () => {
        AuthService.login(creditals.username, creditals.password)
            .then(() => {
                context.setAuth(AuthService.getCurrentUser())
                setLoading(false)
            })
    }

    return (
        <>
            {(loading !== true && context.auth) &&
                <Redirect to={{
                    pathname: "/",
                    state: { from: props.location }
                }}
                />
            }
            <Paper 
            style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "column", width: "100%", padding: 80 }}
            square >
                <img src={LOGO_URL} className="logo" alt="logo" />
                <h2 align="center">BEJELENTKEZÉS</h2>
                <TextField
                    className="full-input"
                    variant="outlined"
                    onChange={onInputChange}
                    name="username"
                    value={creditals.username}
                    label="Felhasználó vagy e-mail" />
                <TextField
                    className="full-input"
                    variant="outlined"
                    onChange={onInputChange}
                    name="password"
                    value={creditals.password}
                    type="password"
                    label="Jelszó" />
                <Button 
                    className="full-input" 
                    onClick={onLogin} >
                    BEJELENTKEZÉS
                </Button>
                <a href="https://smilezor.hu/wp-login.php?action=lostpassword">Elfelejtette jelszavát?</a>
            </Paper>
        </>
    )
}