import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from './AuthContext'


const ProtectedRoute = ({ Component, ...rest }) => {

    const context = useContext(AuthContext);

    return <Route {...rest} render={props => {
        if (context.auth) {
            return <Component {...props} />
        } else {
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }
    }} />
}

export default ProtectedRoute;