import { createRef, useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { IMAGE_URL } from '../Constants'

export default function Slider({ isAbsolute, images, index, setShowSlider, ID }) {

    const [current, setCurrent] = useState(index)
    const focusRef = createRef()

    useEffect(() => {
        focusRef.current.focus()
    }, [])

    const handleKeyPress = (e) => {

        let indx = current

        if (e.keyCode === 37) {
            indx -= 1
        } else if (e.keyCode === 39) {
            indx += 1
        }

        if (indx < 0) indx = images.length - 1
        if (indx > images.length - 1) indx = 0

        setCurrent(indx)

    }

    return (
        <div 
            ref={focusRef}
            className="slider-holder" 
            tabIndex="0"
            onKeyDown={handleKeyPress} >
            <Button 
                onClick={() => setShowSlider(false)}
                style={{position: 'absolute', right: 10, top: 10, color: 'white'}}
                ><CloseIcon /></Button>
            {images.map((image, index) => {
                return (
                    <img
                        key={index}
                        src={isAbsolute ? image : IMAGE_URL + ID + '/' + image}
                        alt="client-pic"
                        className={index === current ? 'slide-image active' : 'slide-image'} />
                )
            })}
        </div>
    )

}