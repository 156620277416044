import { Redirect } from 'react-router-dom'
import useAuthService from "../Auth/AuthService";

export default function Logout(props) {

    const AuthService = useAuthService()

    AuthService.logout()
    
    return (
        <Redirect to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
    )
}