import { getCurrentUser } from "./AuthService";

export default function authHeader () {

    const user = getCurrentUser()

    if (user && user.token){
        return ('Bearer ' + user.token)
    }
    
    return(null);

}